//** This is a temp for s=] to use */
//** Arthor: s=] */
//** Date: 20190608 */
//Comments //***s=]***

import React from "react";
import { BackTop, Icon, List, Tag, message } from "antd";
import intl from "react-intl-universal";
import moment from "moment";

import { throttle, checkImgsByName } from "../resources_component/authimg.js";

import { fetchData, getAccessMode } from "../service/HelperService.js";

import PostsFilter from "./posts_filter.js";
import PostsSorter from "./posts_sorter.js";
import NewsCornerTopPosts from "./posts_top6.js";



class BottomPostList extends React.Component {
  state = {
    currentPage: 1,
    totalPosts: 0,
    postList: [],
    selCateId: 4,
    selSorter: "publish_at:desc",
    loading: false,
  };

  componentDidMount() {
    this.setState((state) => ({ loading: true }));
    let intl_url =
      sessionStorage.getItem("serverPort") +
      "newscorner2/search/?user=" +
      sessionStorage.getItem("@userInfo.id") +
      "&CateId=4&selSorter=publish_at:desc&page=1";

    fetchData(intl_url, "get", null, (response) => {
      if (response.ifSuccess) {
        let res = response.result;
        if (res.status === 200) {
          this.setState((state) => ({
            postList: res.data.list,
            totalPosts: res.total || res.data.total,
            loading: false,
          }));
        } else {
          message.warning(res.status + ": " + res.msg);
          this.setState({ postList: [], totalPosts: 0, loading: false });
        }
      } else {
        this.setState((state) => ({ loading: false }));
      }
    });

  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page, loading: true });

    let pagin_url =
      sessionStorage.getItem("serverPort") +
      "newscorner2/search/?user=" +
      sessionStorage.getItem("@userInfo.id");

    pagin_url += "&CateId=" + this.state.selCateId;

    if (this.state.selSorter !== null) {
      pagin_url += "&selSorter=" + this.state.selSorter;
    }

    pagin_url += "&page=" + page;

    fetchData(pagin_url, "get", null, (response) => {
      if (response.ifSuccess) {
        let res = response.result;
        if (res.status === 200) {
          this.setState({
            postList: res.data.list,
            totalPosts: res.data.total,
            loading: false,
          });
          //   authDivImgByName('auth-div-img-bottom');
        } else {
          message.warning(res.status + ": " + res.msg);
          this.setState({ postList: [], totalPosts: 0, loading: false });
        }
      } else {
        this.setState((state) => ({ loading: false }));
      }
    });
  };

  handleFilterChange = (cateId) => {
    // dunno whare cateid is being passed from
    this.setState({ selCateId: 4, loading: true });
    let filter_url =
      sessionStorage.getItem("serverPort") +
      "newscorner2/search/?user=" +
      sessionStorage.getItem("@userInfo.id");

    filter_url += "&CateId=" + cateId;

    if (this.state.selSorter !== null) {
      filter_url += "&selSorter=" + this.state.selSorter;
    }

    filter_url += "&page=" + this.state.currentPage;

    fetchData(filter_url, "get", null, (response) => {
      if (response.ifSuccess) {
        let res = response.result;
        if (res.status === 200) {
          this.setState({
            postList: res.data.list,
            totalPosts: res.data.total,
            loading: false,
          });
        } else {
          message.warning(res.status + ": " + res.msg);
          this.setState({ postList: [], totalPosts: 0, loading: false });
        }
      } else {
        this.setState((state) => ({ loading: false }));
      }
    });
  };

  handleSorterChange = (selSorter) => {
    this.setState({ selSorter, loading: true });
    let sorter_url =
      sessionStorage.getItem("serverPort") +
      "newscorner2/search/?user=" +
      sessionStorage.getItem("@userInfo.id");

    sorter_url += "&CateId=" + this.state.selCateId;

    if (this.state.selSorter !== null) {
      sorter_url += "&selSorter=" + selSorter;
    }

    sorter_url += "&page=" + this.state.currentPage;

    fetchData(sorter_url, "get", null, (response) => {
      if (response.ifSuccess) {
        let res = response.result;
        if (res.status === 200) {
          this.setState({
            postList: res.data.list,
            totalPosts: res.data.total,
            loading: false,
          });
        } else {
          message.warning(res.status + ": " + res.msg);
          this.setState({ postList: [], totalPosts: 0, loading: false });
        }
      } else {
        this.setState((state) => ({ loading: false }));
      }
    });
  };

  stripHtml = (description) => {
    description = description.replace(/(\n)/g, "");
    description = description.replace(/(\t)/g, "");
    description = description.replace(/(\r)/g, "");
    description = description.replace(/<\/?[^>]*>/g, "");
    // description = description.replace(/\s*/g, "");
    if (description.length > 50) {
      description = description.slice(0, 50) + "...";
    }
    return description;
  };

  render() {
    const { currentPage, totalPosts, postList, loading } = this.state;
    const accessMode = getAccessMode();
    const paginprops = {
      size: accessMode === 3 ? "small" : "",
      defaultCurrent: 1,
      current: currentPage,
      showQuickJumper: true,
      position: "both",
      pageSize: 20,
      total: totalPosts,
      showTotal: (total, range) =>
        accessMode === 3
          ? `Total ${total}`
          : `${range[0]}-${range[1]} of ${total} posts`,
      onChange: this.handlePageChange,
    };

    const spinProp = {
      spinning: loading,
      delay: 500,
      tip: "Loading...",
    };

    const IconText = ({ type, text }) => (
      <span>
        <Icon type={type} style={{ marginRight: 8 }} />
        {text}
      </span>
    );

    return (
      <div>
        {/* <div className="page-content"> */}
        <div className="container blog-list">
          <List
            itemLayout="vertical"
            size="middle"
            loading={spinProp}
            pagination={paginprops}
            dataSource={postList}
            renderItem={(item) => (
              <div className="blog-post clearfix">
                <List.Item
                  key={item.newsCorner2.id}
                  extra={
                    <div
                      className="blog-cover"
                      style={{
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: "150px",
                        width: "272px",
                      }}
                      name="auth-div-img"
                      data-alt="0"
                      alt="POSTS"
                    />
                  }
                >
                  <List.Item.Meta
                    title={
                      <a href={item.newsCorner2.link} target="_blank">
                        {item.newsCorner2.postTitle}{" "}
                      </a>
                    }
                    description={
                      <label>
                        <span>
                          {moment(item.newsCorner2.publishAt).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      </label>
                    }
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        typeof item.blog === "undefined"
                          ? ""
                          : this.stripHtml(item.newsCorner2.content),
                    }}
                  />
                  <div style={{ paddingTop: "10px" }} />
                </List.Item>
              </div>
            )}
          />
        </div>
        {/* </div> */}
      </div>
    );
  }
}

export default class NewsCornerHomeFacebook extends React.Component {
  state = { postList: [], catesList: [], loading: false, theme : this.props.theme };

  componentDidMount() {
    this.setState({
      catesList: require("../temp_json/blog_cates.json"),
      loading: true,
    });
    // let intl_url =
    //   sessionStorage.getItem("serverPort") +
    //   "newscorner2/getLatest/youtube/" +
    //   sessionStorage.getItem("@userInfo.id") +
    //   "/1"; //---1 for create log in backend; 0 for not;

        let intl_url =
      sessionStorage.getItem("serverPort") +
      "newscorner2/search/?user=" +
      sessionStorage.getItem("@userInfo.id") +
      "/1"; //---1 for create log in backend; 0 for not;
    
    console.log("theme", this.props.theme)

    fetchData(intl_url, "get", null, (response) => {
      if (response.ifSuccess) {
        let res = response.result;
        if (res.status === 200) {
          this.setState({
            postList: res.data.list,
            loading: false,
          });
          checkImgsByName("auth-div-img");
          this.props.handleScoring(res.score);
        } else {
          this.setState({
            postList: [],
            loading: false,
          });
        }
      } else {
        this.setState((state) => ({ loading: false }));
      }
    });
    window.addEventListener("scroll", this.onScroll, false);
  }

  onScroll = () => {
    throttle(checkImgsByName("auth-div-img"));
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  render() {
    const { postList, catesList, loading, theme } = this.state;
    const _header = {
      0: "default-mini mini-blog-header",
      1: "night-mini mini-blog-header",
      2: "lightblue-mini mini-blog-header",
      3: "lightgreen-mini mini-blog-header",
      4: "lightpink-mini mini-blog-header",
       
    }

    console.log("theme", this.state.theme)
    const bgColor = {
      0: "",
      1: 'night-home-section',
      2: 'lightblue-home-section',
      3: 'lightgreen-home-section',
      4: 'lightpink-home-section'
    }


    return (
      <div className={bgColor[this.state.theme]}>
        <div className={_header[this.state.theme]} 
        >
          <div className="container clearfix">
            <a href="#/newscorner/facebook">
              {/* <h2>{intl.get("@NEWSCORNER.MINI-BLOG")}</h2> */}
              <h2>Media Corner - Facebook</h2>
            </a>
          </div>
        </div>

        <BottomPostList catesList={catesList} />

        <BackTop />
      </div>
    );
  }
}
