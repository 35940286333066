import create from 'zustand';
import { fetchData } from './service/HelperService';

const useStore = create((set) => ({
  theme: 0, // Default theme
  setTheme: (newTheme) => set(() => ({ theme: newTheme })),
}));


export default useStore;



