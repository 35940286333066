import React from "react";
import { BackTop, List, message } from "antd";
import moment from "moment";
import { fetchData } from "../service/HelperService.js";

class BottomPostList extends React.Component {
  state = {
    currentPage: 1,
    totalPosts: 0,
    postList: [],
    selCateId: 2,
    selSorter: "createdAt:desc",
    loading: false,
  };

  componentDidMount() {
    this.fetchPostList();
  }

  fetchPostList = () => {
    this.setState({ loading: true });
    const getDataUrl =
      sessionStorage.getItem("serverPort") +
      "resource/homepage/instagram/" +
      sessionStorage.getItem("@userInfo.id");

    fetchData(getDataUrl, "get", null, (response) => {
      if (response.ifSuccess) {
        const res = response.result;
        if (res.status === 200 && Array.isArray(res.data)) {
          this.setState({
            postList: res.data,
            totalPosts: res.data.length,
            loading: false,
          });
        } else {
          message.warning(res.status + ": " + res.msg || "No data found");
          this.setState({ postList: [], totalPosts: 0, loading: false });
        }
      } else {
        message.error("Failed to fetch data");
        this.setState({ loading: false });
      }
    });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, this.fetchPostList);
  };

  stripHtml = (content) => {
    content = content.replace(/<\/?[^>]*>/g, "");
    if (content.length > 50) content = content.slice(0, 50) + "...";
    return content;
  };

  render() {
    const { postList, currentPage, totalPosts, loading } = this.state;

    const paginprops = {
      defaultCurrent: 1,
      current: this.state.currentPage,
      pageSize: 20,
      total: this.state.totalPosts,
      showQuickJumper: true,
      position: "both", // Add pagination at both top and bottom
      showTotal: (total) => `Total ${total}`, // Show "Total X"
      onChange: this.handlePageChange,
    };
    

    return (
      <div className="container blog-list">
        <List
          itemLayout="vertical"
          size="middle"
          loading={loading}
          pagination={paginprops}
          dataSource={postList}
          renderItem={(item) => (
            <div
  className="blog-post clearfix"
  style={{ backgroundColor: "#fff", padding: "15px", marginBottom: "10px" }}
>
  <List.Item key={item.id}>
    <List.Item.Meta
      title={<a href={item.link} target="_blank">{item.postTitle}</a>}
      description={moment(item.publishAt).format("YYYY-MM-DD HH:mm:ss")}
    />
  </List.Item>
</div>

          )}
        />
      </div>
    );
  }
}

export default class NewsCornerHomeInstagram extends React.Component {
  state = { theme: this.props.theme };

  render() {
    const { theme } = this.state;

    const _header = {
      0: "default-mini mini-blog-header",
      1: "night-mini mini-blog-header",
      2: "lightblue-mini mini-blog-header",
      3: "lightgreen-mini mini-blog-header",
      4: "lightpink-mini mini-blog-header",
    };

    const bgColor = {
      0: "",
      1: 'night-home-section',
      2: 'lightblue-home-section',
      3: 'lightgreen-home-section',
      4: 'lightpink-home-section'
    }

    return (
      <div className={bgColor[this.state.theme]}>
        <div className={_header[this.state.theme]}>
          <div className="container clearfix">
            <a href="#/newscorner/instagram" target="_blank">
              <h2>Instagram</h2>
            </a>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <BottomPostList />
        </div>
        <BackTop />
      </div>
    );
  }
}

